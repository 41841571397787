import React from 'react'
import './experience.css'
import {BiSolidCheckShield} from 'react-icons/bi'

const Experience = () => {
  return (
    <section id = 'experience'>
      
      <div className='experience__header'>
        <h5>What Skills I Have</h5>
        <h2>My Experience</h2>
      </div>

      <div className= "container experience__container">
        <div className='experience__frontend'>
          <h3> Frontend Development</h3>
          <div className='experience__content'>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>HTML</h4>
                  <small className='text-light'> Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>JavaScript</h4>
                  <small className='text-light'> Beginner</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>Tkinter</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>CSS</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>React JS</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>JavaFX</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
          </div>
        </div>

        <div className='experience__backend'>
        <h3> Backend Development</h3>
          <div className='experience__content'>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>Java</h4>
                  <small className='text-light'> Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>Python</h4>
                  <small className='text-light'> Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>Node JS</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>C/C++</h4>
                  <small className='text-light'> Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>MIPS</h4>
                  <small className='text-light'> Beginner</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>C#</h4>
                  <small className='text-light'> Experienced</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>Swift</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
              <article className='experience__details'>
                <BiSolidCheckShield className='experience__details-icon'/>
                <div>
                  <h4>AWS</h4>
                  <small className='text-light'> Intermediate</small>
                </div>
              </article>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Experience