import React from 'react'
import './contact.css'
import {FiMail} from 'react-icons/fi'
import {PiMessengerLogoBold} from 'react-icons/pi'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = ({ style }) => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_gbic2yf', 'template_fon6xd7', form.current, {
        publicKey: 'I8jYeFoSswr05sFSg',
      })

      

      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

      e.target.reset();
  };

  return (
    <div style={style}>
    <section id = 'contact'>
      
      <div className = "contact__header">
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>
      </div>

      <div className="container contact__container">
        <div className = "contact__options">
          <article className='contact__option'>
            <FiMail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>jamesazanetti@gmail.com</h5>
            <a href="mailto:jamesazanetti@gmail.com" target = "_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <PiMessengerLogoBold className='contact__option-icon'/>
            <h4>Facebook Messenger</h4>
            <h5>James Zanetti</h5>
            <a href="https://m.me/100009992472593" target = "_blank">Send a Message</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>James Z</h5>
            <a href="https://api.whatsapp.com/send?phone=14808685958" target = "_blank">Send a Message</a>
          </article>
        </div>
       {/* END OF CONTACT OPTION SECTION */}
       <form ref={form} onSubmit={sendEmail}>
        <input type="text" name = 'name' placeholder='Your Full Name' required />
        <input type="email" name='email' placeholder='Your Email' required/>
        <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
        <button type='submit' className='btn btn-primary'>Send Message</button>
       </form>
      </div>
    </section>
    </div>
  )
}

export default Contact