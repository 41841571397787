import React from 'react'
import RESUME from '../../assets/jameszanetti_resume.pdf'

const CTA = () => {
  return (
    <div className = 'cta'> 
        <a href = {RESUME} download className = 'btn'>Download Resume</a>
        <a href = "#contact" className = 'btn btn-primary'>Contact me!</a>
    </div>
  )
} 

export default CTA 