import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/foodRandomizer.png'
import IMG2 from '../../assets/websiteProject.png'
import IMG3 from '../../assets/ccompiler.png'
import IMG4 from '../../assets/qgis.png'

const Portfoilo = ({ style }) => {
  return (
    <div style={style}>
    <section id='portfolio'>

      <div className="portfolio__header">
      <h5>What i'm working on!</h5>
      <h2>Projects</h2>
      </div>

      <div className = "container portfolio__container">

        
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <h3>Food Randomizer</h3>
          <div className="portfolio__item-cta">
              <a href="https://github.com/james-database/food-randomizer" rel="noreferrer" target = "_blank" className='btn'>Github</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <h3>React Portfolio Website</h3>
            <div className="portfolio__item-cta">
              <a href="https://jameszanetti.com" className='btn'>Refresh Page</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <h3>Simple Compiler</h3>
          <div className="portfolio__item-cta">
              <a href="https://github.com/james-database/CustomCompiler" rel="noreferrer" target = "_blank" className='btn'>Github</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <h3>GIS Solutions</h3>
          <div className="portfolio__item-cta">
              <a href="https://gdmissionsystems.com/communications/rescue-21" rel="noreferrer" target = "_blank" className='btn'>Visit Contractor</a>
            </div>
        </article>
      
        
      </div>
      
      

    </section>
    </div>
  )
}

export default Portfoilo