import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'

const PersonalSocials = () => {
  return (
    <div className = 'personal__socials'>
        <a href="https://www.linkedin.com/in/james-zanetti-954823234/" target = "_blank"><BsLinkedin/></a>
        <a href="https://github.com/james-database" target = "_blank"><BsGithub/></a>
        <a href="https://www.instagram.com/james_zanetti" target = "_blank"><BsInstagram/></a>
    </div>
  )
}

export default PersonalSocials