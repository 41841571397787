import React from 'react'
import './about.css'
import MYSELF from '../../assets/teaching.JPG'
import {TbAward} from 'react-icons/tb'
import {ImUsers} from 'react-icons/im'
import {PiFolderStarBold} from 'react-icons/pi'

const About = () => {
  return (
    <section id = 'about'>
      &nbsp;
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <br />
      <br />

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src = {MYSELF} alt = "About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <TbAward className='about__icon'/>
              <h5>Experience</h5>
              <div className='about__me-content'>
              <small>Bachelor's Degree in Computer Science</small>
              <small>Lead at Engineering for Kids</small>
              <small>Contracting with General Dynamics</small>
              </div>
            </article>

            <article className='about__card'>
              <PiFolderStarBold className='about__icon'/>
              <h5>Projects</h5>
              <div className='about__me-content'>
              <small>Simple Compiler</small>
              <small>Food Randomizer</small>
              <small>GIS Solutions</small>
              </div>
            </article>

          </div>

          <p>
            Hi I'm James, A software engineer looking to gain 
            experience and meet new people in the STEM field!
            I am currently pursuing a degree in Computer Science
            at ASU and have <a href="#experience"> Experience </a>
            in multiple languages/frameworks. I've linked a few
            <a href='#portfolio'> Projects </a> of mine to checkout!
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About