import React from 'react'
import './footer.css'
import {SlSocialTwitter} from 'react-icons/sl'
import {FaFacebookSquare} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>James Zanetti</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com/100009992472593"><FaFacebookSquare/></a>
        <a href="https://instagram.com/james_zanetti"><FiInstagram/></a>
        <a href="https:/x.com/james__zanetti"><SlSocialTwitter/></a>
      </div>

      
    </footer>
  )
}

export default Footer