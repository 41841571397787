import React from 'react'
import './navigation.css'
import {HiOutlineHome} from 'react-icons/hi'
import {BsPerson} from 'react-icons/bs'
import {BsFolder2Open} from 'react-icons/bs'
import {BsHourglass} from 'react-icons/bs'
import {BiMessageDots} from 'react-icons/bi'
import {useState} from 'react'

const Navigation = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <navigation>
      <a href = "#" onClick = {() => setActiveNav('#')} className = {activeNav === '#' ? 'active' : '' }><HiOutlineHome/></a>
      <a href = "#about" onClick = {() => setActiveNav('#about')} className = {activeNav === '#about' ? 'active' : ''}><BsPerson/></a>
      <a href = "#experience" onClick = {() => setActiveNav('#experience')} className = {activeNav === '#experience' ? 'active' : ''}><BsHourglass/></a>
      <a href = "#portfolio" onClick = {() => setActiveNav('#portfolio')} className = {activeNav === '#portfolio' ? 'active' : ''}><BsFolder2Open/></a>
      <a href = "#contact" onClick = {() => setActiveNav('#contact')} className = {activeNav === '#contact' ? 'active' : ''}><BiMessageDots/></a>
    </navigation>
  )
}

export default Navigation